var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm._TEMPLATE,attrs:{"id":"room"}},[(_vm._TEMPLATE === 'template-1')?[_c('Header',{attrs:{"mainTitle":_vm.header.mainTitle,"subTitle":_vm.header.subTitle,"headerMenu":_vm.header.headerMenu,"titleMenu":_vm.header.titleMenu,"game":_vm.game,"gameType":_vm.game.info.game_type,"gameName":_vm.game.info.game_name,"toggleTitleMenuClass":_vm.toggleTitleMenuClass,"currentComponentIndex":_vm.componentDocker.lastComponentIndex,"auctioneerUI":_vm.auctioneerUI}}),_c('Billboard',{attrs:{"currentDrawing":this.game.currentDrawing,"lastDrawing":this.game.lastDrawing,"userBalance":this.user.balance,"countdownTimer":this.game.info.countdownTimer,"gameType":_vm.game.info.game_type,"nav":this.nav2}}),_c('HorizonMenu',{directives:[{name:"show",rawName:"v-show",value:(!_vm.auctioneerUI),expression:"!auctioneerUI"}],attrs:{"horizonMenu":_vm.horizonMenu,"game":_vm.game}}),_c('section',{class:{
        animation: _vm.componentDocker.animating,
        reverse: _vm.componentDocker.reverse,
      },attrs:{"id":"component-docker"}},[_c('transition',{attrs:{"name":"slide"},on:{"before-enter":function($event){return _vm.beforeEnter(_vm.horizonMenu)},"after-enter":_vm.afterEnter}},[_vm._l((_vm.horizonMenu),function(item){return [(item.highlight)?_c(item.component,{tag:"component"}):_vm._e()]})],2)],1)]:_vm._e(),(_vm._TEMPLATE === 'template-2')?[_c('Header',{attrs:{"mainTitle":_vm.header.mainTitle,"subTitle":_vm.header.subTitle,"headerMenu":_vm.header.TEMPLATE2_headerMenu,"titleMenu":_vm.header.titleMenu,"game":_vm.game,"gameType":_vm.game.info.game_type,"gameName":_vm.game.info.game_name,"toggleTitleMenuClass":_vm.toggleTitleMenuClass,"currentComponentIndex":_vm.componentDocker.lastComponentIndex,"serviceIcon":true,"toggleComponentDocker":this.toggleComponentDocker,"backGameList":true,"userInfo":_vm.user}}),_c('Billboard',{attrs:{"currentDrawing":this.game.currentDrawing,"lastDrawing":this.game.lastDrawing,"userBalance":this.user.balance,"countdownTimer":this.game.info.countdownTimer,"gameType":_vm.game.info.game_type,"gameName":_vm.game.info.game_name,"toggleComponentDocker":this.toggleComponentDocker,"nav":this.nav2,"game":_vm.game}}),(!_vm.auctioneerUI && _vm.componentDockerToggle)?_c('HorizonMenu',{key:1,attrs:{"horizonMenu":_vm.horizonMenu2}}):_vm._e(),(!_vm.auctioneerUI && _vm.componentDockerToggle)?_c('section',{key:2,class:{
        animation: _vm.componentDocker.animating,
        reverse: _vm.componentDocker.reverse,
      },attrs:{"id":"component-docker"}},[_c('transition',{attrs:{"name":"slide"},on:{"before-enter":function($event){return _vm.beforeEnter(_vm.horizonMenu2)},"after-enter":_vm.afterEnter}},[_vm._l((_vm.horizonMenu2),function(item){return [(item.highlight)?_c(item.component,{tag:"component",attrs:{"currentType":item.type}}):_vm._e()]})],2)],1):_vm._e(),(_vm.tradingFloor && !_vm.componentDockerToggle && !_vm.ChatRoomToggle)?_c('TradingFloor',{attrs:{"titleMenu":_vm.header.titleMenu,"headerMenuDockerOpen":this.headerMenuDockerOpen}}):_vm._e(),_c('keep-alive',[(_vm.ChatRoomToggle && !_vm.componentDockerToggle)?_c('ChatRoom',{ref:"chatroom",attrs:{"ChatRoomToggle":this.ChatRoomToggle}}):_vm._e()],1),(_vm.headerMenuDockerOpen)?_c('section',{ref:"headerMenuDocker",attrs:{"id":"header-menu-docker"}},[_c('transition',{attrs:{"name":"slide-fade"},on:{"enter":_vm.slideFadeEnter,"after-enter":_vm.slideFadeAfterEnter,"leave":_vm.slideFadeLeave}},[_vm._l((_vm.nav2),function(item){return [(item.highlight)?_c(item.component,{tag:"component",attrs:{"currentType":item.type}}):_vm._e()]})],2)],1):_vm._e()]:_vm._e(),(_vm._TEMPLATE === 'template-3')?[_c('Header',{attrs:{"mainTitle":_vm.header.mainTitle,"subTitle":_vm.header.subTitle,"headerMenu":_vm.header.TEMPLATE3_headerMenu,"titleMenu":_vm.header.titleMenu,"game":_vm.game,"gameType":_vm.game.info.game_type,"gameName":_vm.game.info.game_name,"toggleTitleMenuClass":_vm.toggleTitleMenuClass,"currentComponentIndex":_vm.componentDocker.lastComponentIndex,"auctioneerUI":_vm.auctioneerUI}}),_c('Billboard',{attrs:{"currentDrawing":this.game.currentDrawing,"lastDrawing":this.game.lastDrawing,"userBalance":this.user.balance,"countdownTimer":this.game.info.countdownTimer,"gameType":_vm.game.info.game_type}}),_c('HorizonMenu',{directives:[{name:"show",rawName:"v-show",value:(!_vm.auctioneerUI),expression:"!auctioneerUI"}],attrs:{"horizonMenu":_vm.horizonMenu}}),_c('section',{class:{
        animation: _vm.componentDocker.animating,
        reverse: _vm.componentDocker.reverse,
      },attrs:{"id":"component-docker"}},[_c('transition',{attrs:{"name":"slide"},on:{"before-enter":function($event){return _vm.beforeEnter(_vm.horizonMenu)},"after-enter":_vm.afterEnter}},[_vm._l((_vm.horizonMenu),function(item){return [(item.highlight)?_c(item.component,{tag:"component"}):_vm._e()]})],2)],1),_c('keep-alive',[(_vm.ChatRoomToggle)?_c('ChatRoom',{ref:"chatroom",attrs:{"game":_vm.game,"ChatRoomToggle":this.ChatRoomToggle}}):_vm._e()],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }