<script>
import { tz_record } from "@/api/beijing28";

export default {
    name: 'TradeHistory',
    components: {
        Header: () => import('components/base/Header'),
    },
    directives: {
        'infinite-scroll': app.Mint.InfiniteScroll,
    },
    data() {
        return {
            fetchEnd: false,
            currentPage: 1,
            tradeHistory: [],

            header: {
                mainTitle: getWord('trade_history')
            },

            gameType: window.localStorage.getItem('game') && JSON.parse(window.localStorage.getItem('game')).type,
        }
    },
    props:[
            'currentType',
        ],
    inject: [

    ],
    methods: {
        profit(gain, pay) {
            return parseFloat(gain - pay).toFixed(2)
        },
        contrast: function (gain, pay, status) {
            return {
                noclose: status === 1 ? true : false,
                contrast: gain - pay > 0 ? true : false
            }
        },
        fetchData(currentPage = this.currentPage) {
            var startTime = this.gameType === 'wanneng'
                ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth() - 1) + '-' + new Date().getDate() + ' 00:00:00').getTime() / 1000
                : new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 00:00:00').getTime() / 1000
            tz_record(
                JSON.parse(localStorage.getItem("game")).type,
                JSON.parse(localStorage.getItem("game")).room,
                startTime,
                new Date().getTime() / 1000,
                null,
                currentPage,
                20
            ).then(result => {
                if (result.data.code == "SUCCESS") {
                    var data = result.data.result;
                    data.list.forEach(item => {
                        this.tradeHistory.push(item)
                    })
                    if (this.currentPage == data.totalPage)
                        this.fetchEnd = true;
                    else {
                        this.currentPage++;
                    }
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        }
    },
    provide: function () {

    },
    mounted() {
        if (_TEMPLATE === 'template-3') {
            this.CustomScroll(this.$refs.tradeHistoryList.id);
        } else {
            this.CustomScroll(this.$refs.tradeHistory.id);
        }
    },
    computed: {

    },
    watch: {

    }
};
</script>
<template>
    <div id="trade-history" ref="tradeHistory" :class="_TEMPLATE">
        <template v-if="_TEMPLATE === 'template-1' || _TEMPLATE === 'template-2'">
         <Header v-if="_TEMPLATE === 'template-2'" :mainTitle="header.mainTitle" :backButton="false" />
            <table>
                <thead>
                    <tr>
                        <th width="30%">{{ getWord('drawing_number') }}</th>
                        <th width="30%">{{ getWord('content') }}</th>
                        <th width="17%">{{ getWord('amount_of_betting') }}</th>
                        <th width="17%">{{ getWord('result') }}</th>
                    </tr>
                </thead>
                <tbody v-infinite-scroll="fetchData" infinite-scroll-distance="10" infinite-scroll-disabled="fetchEnd">
                    <tr v-for="(item, index) in tradeHistory">
                        <td>
                            {{ item.issue }}{{ getWord('period') }}
                        </td>
                        <td>
                            {{ item.bet_name }}
                            <template v-if="item.lottery_class.indexOf('LIANMA') > -1
                                || item.lottery_class === 'WEI_LIAN'
                                || item.lottery_class === 'ANIMAL_LIAN'
                                || item.lottery_class === 'ALLNOTIN'
                                || item.lottery_class === 'QIANER'
                                || item.lottery_class === 'QIANSAN'
                                || item.lottery_class === 'ZUXUANLIU'
                                || item.lottery_class === 'ZUXUANSAN'
                                || item.lottery_class === 'REN_2'
                                || item.lottery_class === 'REN_ZU_2'
                                || item.lottery_class === 'REN_3'
                                || item.lottery_class === 'REN_ZU_3'
                                || item.lottery_class === 'REN_4'
                                || item.lottery_class === 'REN_5'
                                || item.lottery_type === 'wanneng'
                            ">
                                @{{ item.bet_number }}
                            </template>
                            <br />
                            {{ new Date(item.created_at * 1000).toLocaleString().split(' ')[0] }}
                            {{ new Date(item.created_at * 1000).toTimeString().split(' ')[0] }}
                        </td>
                        <td>{{ parseFloat(item.money).toFixed(2) }}</td>
                        <td class="gain" :class="contrast(item.bonus_money, item.money, item.status)">{{
                                item.status === 2 ? profit(item.bonus_money, item.money) : getWord('unsettled_account')
                        }}</td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template v-if="_TEMPLATE === 'template-3'">
            <ul v-infinite-scroll="fetchData" infinite-scroll-distance="10" infinite-scroll-disabled="fetchEnd"
                ref="tradeHistoryList" id="trade-history-list">
                <li v-for="(item, index) in tradeHistory">
                    <label>{{ getWord('number_of_item') }}：{{ item.bet_name }}
                        <template v-if="item.lottery_class.indexOf('LIANMA') > -1
                            || item.lottery_class === 'WEI_LIAN'
                            || item.lottery_class === 'ANIMAL_LIAN'
                            || item.lottery_class === 'ALLNOTIN'
                            || item.lottery_class === 'QIANER'
                            || item.lottery_class === 'QIANSAN'
                            || item.lottery_class === 'ZUXUANLIU'
                            || item.lottery_class === 'ZUXUANSAN'
                            || item.lottery_class === 'REN_2'
                            || item.lottery_class === 'REN_ZU_2'
                            || item.lottery_class === 'REN_3'
                            || item.lottery_class === 'REN_ZU_3'
                            || item.lottery_class === 'REN_4'
                            || item.lottery_class === 'REN_5'
                            || item.lottery_type === 'wanneng'
                            || item.lottery_type.indexOf('fiveselect') > -1 && (item.lottery_class === 'ONE' || item.lottery_class === 'TWO' || item.lottery_class === 'THREE' || item.lottery_class === 'FOUR' || item.lottery_class === 'FIVE')
                            || item.lottery_type.indexOf('ssc') > -1 && (item.lottery_class === 'ONE' || item.lottery_class === 'TWO' || item.lottery_class === 'THREE' || item.lottery_class === 'FOUR' || item.lottery_class === 'FIVE')
                            || item.lottery_type === 'azxy5' && (item.lottery_class === 'ONE' || item.lottery_class === 'TWO' || item.lottery_class === 'THREE' || item.lottery_class === 'FOUR' || item.lottery_class === 'FIVE')
                            || item.lottery_type === 'azxy10' && (item.lottery_class === 'ONE' || item.lottery_class === 'TWO' || item.lottery_class === 'THREE' || item.lottery_class === 'FOUR' || item.lottery_class === 'FIVE' || item.lottery_class === 'SIX' || item.lottery_class === 'SEVEN' || item.lottery_class === 'EIGHT' || item.lottery_class === 'NINE' || item.lottery_class === 'TEN')
                            || item.lottery_type === 'scone' && (item.lottery_class === 'ONE' || item.lottery_class === 'TWO' || item.lottery_class === 'THREE' || item.lottery_class === 'FOUR' || item.lottery_class === 'FIVE' || item.lottery_class === 'SIX' || item.lottery_class === 'SEVEN' || item.lottery_class === 'EIGHT' || item.lottery_class === 'NINE' || item.lottery_class === 'TEN')
                            || item.lottery_type === 'scthree' && (item.lottery_class === 'ONE' || item.lottery_class === 'TWO' || item.lottery_class === 'THREE' || item.lottery_class === 'FOUR' || item.lottery_class === 'FIVE' || item.lottery_class === 'SIX' || item.lottery_class === 'SEVEN' || item.lottery_class === 'EIGHT' || item.lottery_class === 'NINE' || item.lottery_class === 'TEN')
                            || item.lottery_type === 'scfive' && (item.lottery_class === 'ONE' || item.lottery_class === 'TWO' || item.lottery_class === 'THREE' || item.lottery_class === 'FOUR' || item.lottery_class === 'FIVE' || item.lottery_class === 'SIX' || item.lottery_class === 'SEVEN' || item.lottery_class === 'EIGHT' || item.lottery_class === 'NINE' || item.lottery_class === 'TEN')
                        ">
                            @{{ item.bet_number }}
                        </template>
                    </label>
                    <label>{{ getWord('betting2') }}：{{ parseFloat(item.money).toFixed(2) }}</label>
                    <label>{{ getWord('drawing_number') }}：{{ item.issue }}</label>
                    <label>{{ getWord('time') }}：{{ new Date(item.created_at * 1000).toISOString().split('T')[0] +
                            '&nbsp;&nbsp;' + new Date(item.created_at * 1000).toTimeString().split(' ')[0]
                    }}</label>
                    <label>{{ getWord('status') }}：<b class="gain"
                            :class="contrast(item.bonus_money, item.money, item.status)">
                            {{ item.status === 2
                                    ? profit(item.bonus_money, item.money)
                                    : getWord('unsettled_account')
                            }}
                        </b></label>
                </li>
            </ul>
        </template>
    </div>
</template>
<style lang='scss' scoped>
#trade-history {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow: auto;

    &.template-3 {
        background-color: #EFEFF4;
        overflow: hidden;

        ul {
            overflow: auto;
        }
    }

    table {
        width: 100%;
        font-size: .28rem;
        border-collapse: collapse;

        tr {
            border-bottom: 1px solid #f3f3f4;
        }

        th,
        td {
            padding: .3rem .125rem;

            &:first-child {
                padding-left: .25rem;
            }

            &:last-child {
                padding-right: .25rem;
            }
        }

        th {
            border: 1px solid #EBEAF1;
            border-left: 0;
            border-right: 0;
            font-size: .24rem;
            background-color: #F3F3F4;
            color: #999999;
            text-align: left;
        }

        td {

            &.gain {
                color: #E83535;
            }

            &.contrast {
                color: #54B61C;

                &:before {
                    content: "+";

                }
            }

            &.noclose {
                color: #333333;
            }
        }

    }

    ul {
        padding: .1rem;
        padding-top: 0;

        li {
            font-size: .3rem;
            position: relative;
            background-color: #ffffff;
            padding: .15rem .25rem;

            &:nth-child(odd) {
                background-color: #EEEEEE;
            }

            label {
                display: block;
                line-height: 1.75;
            }

            b {
                // position: absolute;
                right: .25rem;
                top: .15rem;
                // font-size: .4rem;
                font-weight: normal;

                &.gain {
                    color: #E83535;
                }

                &.contrast {
                    color: #54B61C;

                    &:before {
                        content: "+";

                    }
                }

                &.noclose {
                    color: #333333;
                }
            }
        }
    }
}
</style>