<script>
import {
  cloudBaseUrl,
  getClientTime,
  headerMenu,
  TEMPLATE2_headerMenu,
  lastDrawingRule,
  TEMPLATE1_titleMenu,
  TEMPLATE2_titleMenu,
  TEMPLATE3_titleMenu,
  TEMPLATE3_headerMenu,
} from "@/CONF.js";

import axios from "axios";
import $sock from "@/components/WebSocket"; //聊天的js文件
import {
  issue,
  chat,
  roomLogin,
  roomLogout,
  getTalkRoomSetting,
  getRoomConfig,
} from "@/api/beijing28"; //获取当前期号已经封盘时间信息
import { prev_issue } from "@/api/beijing28"; //获取上一期期号与开奖信息
import { system_message } from "@/api/beijing28"; //时间节点系统消息列表
import { account_balance } from "@/api/beijing28"; //账号余额
import { chat_bet } from "@/api/beijing28"; //聊天投注
import { dtMessageBet } from "@/api/beijing28"; //聊天投注
import { caizhong as getTypeOfGame } from "@/api/home"; //彩种信息

import ChatRoom from "components/pc28/ChatRoom";
import TradeHistory from "components/pc28/TradeHistory";
import DrawingHistory from "components/pc28/DrawingHistory";
import TrendsToChart from "components/pc28/TrendsToChart";
import TrendsByLuZhu from "components/pc28/TrendsByLuZhu";

export default {
  name: "GameRoom",
  components: {
    Header: () => import("components/base/Header"),
    ChatRoom: () => import("components/pc28/ChatRoom"),
    Billboard: () => import("components/pc28/Billboard"),
    TradingFloor: () => import("components/base/TradingFloor"),
    HorizonMenu: () => import("components/pc28/HorizonMenu"),
    // TradingHistory: () => import('components/pc28/TradeHistory'),
    // TradingHistory2: () => import('views/action/historyLottery'),
  },
  data() {
    return {
      //组件变量
      cloudBaseUrl, //oss地址前缀
      wsConnection: null,

      auctioneerUI: false,
      dxds: "?", //开奖结果的单双
      system_message: [], //系统消息通知常量
      user: {
        avatar: null,
        balance: "00",
      },
      game: {
        info: {
          game_type: "", //游戏类型
          game_room: "", //游戏房间id
          game_name: "", //游戏名字,
          game_room_type: "", //房间类型
          countdownTimer: "",
          currentAuctioneerUIClass: null,
          chatroom: null,
          badword: null,
        },
        currentDrawing: [],
        lastDrawing: [],
      },
      timer: {
        clean_setInterval: null,
        retriveLastDrawing: null,
        retriveCurrentDrawing: null,
        retriveCurrentDrawingRepeat: null,
      },
      header: {
        mainTitle: "北京28",
        subTitle: "2019/03/15",
        headerMenu: headerMenu(),
        TEMPLATE2_headerMenu: TEMPLATE2_headerMenu(),
        TEMPLATE3_headerMenu: TEMPLATE3_headerMenu(),
        titleMenu: [],
      },

      componentDocker: {
        reverse: false,
        animating: false,
        lastComponentIndex: null,
      },
      lastDrawingRule,

      tradingFloor: true,
      ChatRoomToggle: false,

      nav2: [
        {
          title: getWord("betting"),
          icon: "icon-schedule",
          color: "#4393FF",
          component: () => import("components/UI_2/query_record"),
          highlight: false,
          id: "1",
        },
        {
          title: getWord("history"),
          icon: "icon-openhis",
          color: "#62A8A5",
          component: () => import("components/pc28/DrawingHistory"),
          highlight: false,
          id: "2",
          type: "no-header",
        },
        {
          title: getWord("trends"),
          linkTo: "/action/query_luzhu",
          linkTo2: "/game/trendsByLuzhu",
          icon: "icon-trend",
          color: "#FF0F0F",
          component: () => import("views/action/query_luzhu"),
          highlight: false,
          linkTo: "/action/query_luzhu",
          id: "3",
        },
        {
          title: getWord("explain"),
          linkTo: "/UserGuide",
          icon: "icon-info",
          color: "#41A441",
          component: () => import("components/UI_2/UserGuide"),
          highlight: false,
          id: "4",
        },
        {
          tag: "chatroom",
          title: getWord("chat"),
          icon: "icon-msg ",
          color: "#41A441",
          component: () => import("components/pc28/ChatRoom"),
          highlight: false,
          id: "5",
        },
      ],
      componentDockerToggle: null,
      auctioneerUIToggle: null,

      headerMenuDockerOpen: false,
      roomTypesAdded: ["cqssc", "bjsc", "xyft"],

      horizonMenu: [
        {
          title: getWord("auctioneer_by_ui3"),
          icon: "icon-coin",
          component: () => import("components/base/TradingFloor"),
          highlight: true,
        },
        {
          title: getWord("trade_history"),
          icon: "icon-schedule",
          component: TradeHistory,
          highlight: false,
        },
        {
          title: getWord("luzhu") + "/" + getWord("changlong"),
          icon: "icon-trend",
          component: TrendsByLuZhu,
          highlight: false,
        },
        {
          title: getWord("drawing_result"),
          icon: "icon-trophy",
          component: DrawingHistory,
          highlight: false,
        },
        {
          tag: "chatroom",
          title: getWord("chatroom2"),
          icon: "icon-msg",
          component: ChatRoom,
          highlight: false,
        },
      ],
      horizonMenu2: [
        {
          title: getWord("drawing_result"),
          icon: "drawing-history",
          component: () => import("components/pc28/DrawingHistory"),
          highlight: true,
          type: "no-header",
        },
        {
          title: getWord("analysis"),
          icon: "analysis",
          component: () => import("components/pc28/Analysis"),
          highlight: false,
        },
        {
          title: getWord("changlong2"),
          icon: "longline",
          component: () => import("components/pc28/TrendsByLuZhu"),
          highlight: false,
          type: "long",
        },
        {
          title: getWord("luzhu_odd"),
          icon: "luzhu-odd",
          component: () => import("components/pc28/TrendsByLuZhu"),
          highlight: false,
          type: "odd",
        },
        {
          title: getWord("luzhu_size"),
          icon: "luzhu-size",
          component: () => import("components/pc28/TrendsByLuZhu"),
          highlight: false,
          type: "size",
        },
        {
          title: getWord(["number_of_item", "trends"]),
          icon: "trends-to-chart",
          component: () => import("components/pc28/TrendsToChart"),
          highlight: false,
        },
      ],
      horizonMenu3: [
        {
          title: getWord("drawing_result"),
          icon: "drawing-history",
          component: () => import("components/pc28/DrawingHistory"),
          highlight: true,
        },
        {
          title: getWord("analysis"),
          icon: "analysis",
          component: () => import("components/pc28/Analysis"),
          highlight: false,
        },
      ],

      titleMenu: TEMPLATE1_titleMenu(),
      TEMPLATE1_titleMenu: TEMPLATE1_titleMenu(),
      TEMPLATE2_titleMenu: TEMPLATE2_titleMenu(),
      TEMPLATE3_titleMenu: TEMPLATE3_titleMenu(),
    };
  },
  methods: {
    messageFilter(item) {
      var data = item.msg;
      if (this.game.info.badword && this.game.info.badword.slice(-1) === "/") {
        this.game.info.badword = this.game.info.badword.slice(0, -1);

        var badword = this.game.info.badword.replace(/\//g, "|");
        var pattern = new RegExp(badword, "g");

        var _data = data.replace(pattern, "*");
        return _data;
      } else {
        return item.msg;
      }
    },
    highlightHorizonMenu(horizonMenu, data) {
      if (data.linkTo) {
        this._linkTo({
          path: data.linkTo,
          query: {
            type: this.game.info.game_type,
          },
        });
      }
      horizonMenu.forEach((item) => {
        item.highlight = false;
      });
      var current = horizonMenu.filter((item) => {
        return item.title === data.title;
      })[0];

      current.highlight = true;

      this.componentDocker.lastComponentIndex = horizonMenu.findIndex(
        (item) => {
          return item.highlight === true;
        }
      );
    },

    auctioneerUIActive() {
      this.auctioneerUI = !this.auctioneerUI;
    },
    sendMessage(msg, callback) {
      var that = this;
      var type = null;
      if (typeof msg === "object" && msg !== null && !(msg instanceof Array)) {
        if (msg.type === "text") {
          msg = "SHORTCUT_TEXT__" + msg.value;
          type = "text";
        }
        if (msg.type === "image") {
          msg = "SHORTCUT_IMAGE__" + msg.id;
          type = "image";
        }
      }
      if (msg.trim() == "") {
        this.errorText = getWord("chatroom_info3");
        setTimeout(() => {
          this.errorTextType = true;
        }, 500);
        setTimeout(() => {
          this.errorTextType = false;
        }, 2000);
        return false;
      } else {
        var data_time = getClientTime();

        // if (selfSign) {
        var related = this.$store.state.chatRoomRepeatHongbaos.filter(
          (item) => {
            return item.repeatString === msg && !item.state;
          }
        );
        if (related.length > 0) {
          this.$refs.chatroom.getHongbaoID(related[0], msg);
        }
        // }

        $sock.send(
          "Index/say",
          {
            nickname: this.chatroomNickname,
            message: msg,
            time: data_time,
            headImage: JSON.parse(localStorage.getItem("user")).headImage,
            // grade: this.userInfo.grade_name,
          },
          function (data) {
            console.log(data);
          }
        );
        if (type === "text") {
          that.$store.commit("UPDATE_chatRoomMessages", {
            type: "self-SHORTCUT_TEXT",
            nickname: this.chatroomNickname,
            msg: msg.split("SHORTCUT_TEXT__")[1],
            time: data_time,
            avatar: JSON.parse(localStorage.getItem("user")).headImage,
            // grade: this.userInfo.grade_name,
          });
        } else if (type === "image") {
          that.$store.commit("UPDATE_chatRoomMessages", {
            type: "self-SHORTCUT_IMAGE",
            nickname: this.chatroomNickname,
            msg: msg.split("SHORTCUT_IMAGE__")[1],
            time: data_time,
            avatar: JSON.parse(localStorage.getItem("user")).headImage,
            // grade: this.userInfo.grade_name,
          });
        } else {
          that.$store.commit("UPDATE_chatRoomMessages", {
            type: "self",
            nickname: this.chatroomNickname,
            msg: msg,
            time: data_time,
            avatar: JSON.parse(localStorage.getItem("user")).headImage,
            // grade: this.userInfo.grade_name,
          });
        }

        this.input_value = "";
        this.$nextTick(function () {
          typeof callback === "function" && callback();
        });
      }
    },
    //发送投注
    send(msg, callback) {
      var that = this;

      if (this.$route.query.type.indexOf("28") === -1) {
        var format_msg = msg.split("/");
        if (format_msg.length > 3) {
          format_msg.length = 3;
        }
        var msg_money = format_msg[format_msg.length - 1];
        format_msg.length = format_msg.length - 1;

        if (isNaN(msg_money)) {
          app.Mint.Toast("金额输入错误");
          return false;
        }
        if (!isNaN(msg_money)) {
          if (msg_money % 1 !== 0) {
            format_msg =
              format_msg.join("/") + "/" + Number(msg_money).toFixed(2);
          } else {
            format_msg = msg;
          }
        }
      }

      if (this.$route.query.type.indexOf("28") === -1) {
        dtMessageBet(
          this.game.currentDrawing.issue,
          this.game.info.game_room,
          this.game.info.game_type,
          format_msg
        ).then((result) => {
          if (result.data.code === "SUCCESS") {
            handleSuccess(result);
          } else {
            app.Mint.Toast(result.data.msg);
          }
        });
      } else {
        chat_bet(
          this.game.currentDrawing.issue,
          this.game.info.game_room,
          this.game.info.game_type,
          msg
        ).then((result) => {
          if (result.data.code === "SUCCESS") {
            handleSuccess(result);
          } else {
            app.Mint.Toast(result.data.msg);
          }
        });
      }

      function handleSuccess(responseData) {
        var data = responseData.data.result.user.lotteries;

        //send boardcast request
        $sock.send(
          "Index/sayBet",
          {
            message: combinedMsg(data),
          },
          function (data) {
            console.log("request ws server boardcast", data);
          }
        );

        var data_time = getClientTime();
        var lotteries = responseData.data.result.user.lotteries;
        var lotteries_data = "";
        var prefix =
          that.game.info.game_type.indexOf("28") === -1
            ? ""
            : getWord("item_tema") + "-";
        for (var i in lotteries) {
          if (i == lotteries.length - 1) {
            lotteries_data +=
              prefix +
              lotteries[i].name +
              " " +
              this.currencySymbol() +
              lotteries[i].money;
          } else {
            lotteries_data +=
              prefix +
              lotteries[i].name +
              " " +
              this.currencySymbol() +
              lotteries[i].money +
              "、";
          }
        }

        that.$store.commit("UPDATE_tradingFloorMessages", {
          type: "bet-self",
          nickname: responseData.data.result.user.nickname,
          msg: lotteries_data,
          time: data_time,
          avatar: responseData.data.result.user.headImage,
          drawingIndex: that.game.currentDrawing.issue,
        });

        that.$nextTick(function () {
          typeof callback === "function" && callback();
        });

        app.Mint.Toast(responseData.data.msg);
      }
      function combinedMsg(data) {
        var result = [];
        data.forEach((item) => {
          result.push(
            Object.assign(
              {
                avatar: that.user.avatar,
                nickname: JSON.parse(localStorage.getItem("user")).nickname,
              },
              item
            )
          );
        });
        return result;
      }
    },
    checkDxds(data) {
      if (data <= 13 && data % 2 != 0) {
        return "(" + getWord("xiaodan") + ")";
      } else if (data <= 13 && data % 2 == 0) {
        return "(" + getWord("xiaoshuang") + ")";
      } else if (data >= 14 && data % 2 != 0) {
        return "(" + getWord("dadan") + ")";
      } else if (data >= 14 && data % 2 == 0) {
        return "(" + getWord("dashuang") + ")";
      } else {
        if (this._TEMPLATE === "template-3") {
          return "(??)";
        } else {
          return "";
        }
      }
    },
    //计时器 , 封盘倒数提示
    while_time() {
      var that = this;
      // var second_num = '';
      that.timer.clean_setInterval = setInterval(function () {
        if (that.game.info.countdownTimer > 0) {
          //countdown
          if (that.game.info.countdownTimer == 60) {
            that.$store.commit("UPDATE_tradingFloorMessages", {
              type: "boardcast",
              msg: that.system_message.where_60,
              date: new Date().toLocaleString().split(" ")[1],
            });
          }
          if (that.game.info.countdownTimer == 30) {
            that.$store.commit("UPDATE_tradingFloorMessages", {
              type: "boardcast",
              msg: that.system_message.where_30,
              date: new Date().toLocaleString().split(" ")[1],
            });
          }
          if (that.game.info.countdownTimer == 10) {
            that.$store.commit("UPDATE_tradingFloorMessages", {
              type: "boardcast",
              msg: that.system_message.where_10,
              date: new Date().toLocaleString().split(" ")[1],
            });
          }
          that.game.info.countdownTimer--;
          if (that.game.info.countdownTimer <= 0) {
            clearInterval(that.timer.clean_setInterval);
            that.game.info.countdownTimer = getWord("wait_for_handicap_renew");
            var start_bets =
              parseInt(that.game.lastDrawing.issue) +
              2 +
              getWord("period") +
              " " +
              that.system_message.where_start;
            setTimeout(function () {
              that.retriveCurrentDrawing();
              that.$store.commit("UPDATE_tradingFloorMessages", {
                type: "boardcast",
                msg: start_bets,
                drawing: parseInt(that.game.lastDrawing.issue) + 2,
                title: getWord("betting_tips5"),
                date: new Date().toLocaleString().split(" ")[1],
              });
              that.retriveLastDrawing();
            }, 6000);
          }
        } else {
          clearInterval(that.timer.clean_setInterval);

          that.retriveCurrentDrawing(function () {
            that.timer.retriveCurrentDrawingRepeat = setTimeout(function () {
              //remider to bet next turn
              var start_bets =
                parseInt(that.game.lastDrawing.issue) +
                2 +
                getWord("period") +
                " " +
                that.system_message.where_start;

              that.$store.commit("UPDATE_tradingFloorMessages", {
                type: "boardcast",
                msg: start_bets,
                drawing: parseInt(that.game.lastDrawing.issue) + 2,
                title: getWord("betting_tips5"),
                date: new Date().toLocaleString().split(" ")[1],
              });

              that.retriveLastDrawing();
            }, 2000);
          });

          that.clearBillboard(200);
        }
      }, 1000);
    },
    //获取当前期号已经封盘时间信息
    retriveCurrentDrawing(callback) {
      var that = this;
      var type = this.game.info.game_type;
      var _result = null;
      if (
        this.$store.state.currentDrawing &&
        this.$store.state.currentDrawing[type]
      ) {
        _result = { data: { result: this.$store.state.currentDrawing[type] } };
        handle();
      } else {
        if (that.timer.retriveCurrentDrawing) {
          clearTimeout(that.timer.retriveCurrentDrawing);
        }
        that.timer.retriveCurrentDrawing = setTimeout(function () {
          that.retriveCurrentDrawing();
        }, 1000);
        // issue(type).then(result=>{
        //     if (result.data.code == "SUCCESS") {
        //         _result=result;
        //         handle();
        //     }
        // })
      }

      function handle() {
        that.game.currentDrawing = _result.data.result;
        that.game.info.countdownTimer = _result.data.result.time_diff;
        if (!(Number(that.game.info.countdownTimer) > 0)) {
          that.game.info.countdownTimer = that.system_message.where_ending;
          //todo server sent "open time"
          // console.log('Failure on retrives "'+that.game.info.game_type+'" current drawing information.')
          if (that.timer.retriveCurrentDrawing) {
            clearTimeout(that.timer.retriveCurrentDrawing);
          }
          that.timer.retriveCurrentDrawing = setTimeout(function () {
            that.retriveCurrentDrawing();
          }, 1000);
        } else {
          that.while_time();
          if (callback) {
            callback();
          }
        }
      }
    },
    //获取上一期期号与开奖信息
    retriveLastDrawing() {
      var that = this;
      var _result = null;

      var type = this.game.info.game_type;
      if (
        this.$store.state.lastDrawing &&
        this.$store.state.lastDrawing[type]
      ) {
        _result = this.$store.state.lastDrawing[type];
        that.game.lastDrawing = _result;
        check();
      } else {
        // prev_issue(this.game.info.game_type).then(result => {
        //     if (result.data.code === 'SUCCESS') {
        //         _result = result.data.result;
        //         that.game.lastDrawing=_result;
        //         //todo api logical error when after close few seconds.
        //         // if (that.game.lastDrawing.length===0) {
        //         //     console.log('initialized game.lastDrawing data.')
        //         //     check()
        //         // }else {
        //             check()
        //         // }
        //     }
        // })
      }
      function handle() {
        console.log(
          'Succeesful on updates "' +
            that.game.info.game_type +
            '" last drawing'
        );

        var open_result = "";

        switch (that.game.info.game_type) {
          case "bj28":
          case "jnd28":
          case "twbg28":
          case "mgelg28":
          case "jndx28":
          case "snfk28":
          case "pc28one":
            open_result =
              "" +
              " " +
              that.game.lastDrawing.num_one +
              "+" +
              that.game.lastDrawing.num_two +
              "+" +
              that.game.lastDrawing.num_three +
              "=" +
              that.game.lastDrawing.num_count;
            break;
          case "bjsc":
          case "xyft":
            var _msg = [];
            lastDrawingRule.forEach((item, index) => {
              if (index < 10) {
                _msg.push(that.game.lastDrawing[item]);
              }
            });
            open_result = that.system_message.where_open + " " + _msg.join(",");
            break;
          case "cqssc":
            var _msg = [];
            lastDrawingRule.forEach((item, index) => {
              if (index < 5) {
                _msg.push(that.game.lastDrawing[item]);
              }
            });
            open_result = that.system_message.where_open + " " + _msg.join(",");
            break;
          default:
            break;
        }

        that.$store.commit("UPDATE_tradingFloorMessages", {
          type: "boardcast",
          msg: open_result,
          drawing: that.game.lastDrawing.issue,
          title: getWord("drawing_result"),
          date: new Date().toLocaleString().split(" ")[1],
        });
      }

      function check() {
        if (_result.num_count === "?") {
          // console.log('Failure on retrives "'+that.game.info.game_type+'" last drawing, reason: no info')

          //todo server sent "get last drawing information"
          if (that.timer.retriveLastDrawing) {
            clearTimeout(that.timer.retriveLastDrawing);
          }
          that.timer.retriveLastDrawing = setTimeout(function () {
            that.retriveLastDrawing();
          }, 10000);
        } else {
          handle();
        }

        that.checkDxds(_result.num_count);
      }
    },
    //默认系统消息常量
    system_message_function() {
      system_message(this.game.info.game_type, this.game.info.game_room).then(
        (result) => {
          if (result.data.code === "SUCCESS") {
            this.system_message = result.data.result;
            this.$store.commit("UPDATE_tradingFloorMessages", {
              type: "boardcast",
              msg: this.system_message.in_room,
              date: new Date().toLocaleString().split(" ")[1],
            });
            this.retriveCurrentDrawing();
            this.retriveLastDrawing();
          }
        }
      );
    },
    addMessage(data) {
      this.tradingFloorMessages.push(data);
    },
    //账号余额
    function_account_balance() {
      if (this.$store.state.userBalance) {
        this.user.balance = this.$store.state.userBalance;
      } else {
        account_balance().then((result) => {
          if (result.data.code === "SUCCESS") {
            this.user.balance = result.data.result.money;
          }
        });
      }
    },
    //跳转到底部
    scrollEnd(elem) {
      elem.scrollTop = elem.scrollHeight;
    },
    routerlinkTo(url) {
      this.$router.push(url);
    },
    clearBillboard(min = 100) {
      if (this.$refs.dataListContent) {
        var obj = this.$refs.dataListContent;
        var entries = obj.childNodes;
        var entriesLength = entries.length;
        if (entriesLength > min) {
          entries.forEach((item, index) => {
            if (index < entriesLength - min) obj.removeChild(item);
          });
        }
      } else {
        return false;
      }
    },
    calcHeight(elem, diff, percentageOfFull = 1) {
      var rem = (window.innerWidth / 375) * 50;
      var _height = window.innerHeight * percentageOfFull - rem * diff;
      if (_height < 0) {
        _height = 0;
      }
      document.getElementById(elem).style.height = _height + "px";
    },
    beforeEnter(horizonMenu, el) {
      var currentIndex = horizonMenu.findIndex((item) => {
        return item.highlight === true;
      });
      if (currentIndex < this.componentDocker.lastComponentIndex) {
        this.componentDocker.reverse = true;
      }
      this.componentDocker.animating = true;
    },
    afterEnter() {
      this.componentDocker.animating = false;
    },
    toggleTitleMenuClass(data) {
      if (data) {
        this.header.titleMenu.forEach((item) => {
          var activedItem = item.list.filter((item) => {
            return item.active === true;
          });
          if (activedItem.length) {
            activedItem[0].active = false;
          }
          var currentItem = item.list.filter((item) => {
            return item.title === data.class;
          });
          if (currentItem.length) {
            currentItem[0].active = true;
          }
        });
        this.game.info.currentAuctioneerUIClass = data;
      } else {
        this.header.titleMenu.forEach((item) => {
          item.list.forEach((item) => {
            item.active === false;
          });
        });
      }
    },
    initTitleMenuClass() {
      if (this.header.titleMenu && this.header.titleMenu.length) {
        this.game.info.currentAuctioneerUIClass = {
          class: this.header.titleMenu[0].list[0].title,
          id: this.header.titleMenu[0].list[0].id,
          type: this.game.info.game_type,
          classType: this.header.titleMenu[0].type,

          alias: this.header.titleMenu[0].list[0].alias,
          classTypeId: this.header.titleMenu[0].id,
        };
        console.log(this.game.info.currentAuctioneerUIClass);
      }
    },
    toggleComponentDocker() {
      this.componentDockerToggle = !this.componentDockerToggle;
      this.TEMPLATE2_toggleComponentDocker(this.componentDockerToggle);
    },
    toggleHeaderMenuDocker(close) {
      if (close) {
        this.headerMenuDockerOpen = false;
      } else {
        if (this.headerMenuDockerOpen) return false;
        this.headerMenuDockerOpen = !this.headerMenuDockerOpen;
      }
    },
    toggleChatRoom(close) {
      if (close) {
        this.tradingFloor = true;
        this.ChatRoomToggle = false;
      } else {
        this.ChatRoomToggle = !this.ChatRoomToggle;
      }
    },
    slideFadeEnter() {
      this.$refs.headerMenuDocker.className += " active";
    },
    slideFadeAfterEnter() {
      this.$refs.headerMenuDocker.className =
        this.$refs.headerMenuDocker.className.replace(" active", "");
    },
    slideFadeLeave() {
      this.$refs.headerMenuDocker.className += " active";
    },
  },
  computed: {
    tradingFloorMessages() {
      return this.$store.state.tradingFloorMessages;
    },
    chatRoomMessages() {
      return this.$store.state.chatRoomMessages;
    },
    chatRoomRepeatHongbaos() {
      return this.$store.state.chatRoomRepeatHongbaos;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    chatroomNickname() {
      return this.$store.state.userInfo.talkroom_nick_name;
    },
  },
  watch: {},
  created() {
    if (
      (this._TEMPLATE === "template-2" || this._TEMPLATE === "template-1") &&
      this.roomTypesAdded.filter((item) => {
        return item === this.$route.query.type;
      }).length === 1
    ) {
      this.nav2[2].linkTo = this.nav2[2].linkTo2;
    }
  },
  mounted() {
    var that = this;
    if (localStorage.getItem("user")) {
      this.user.avatar = JSON.parse(localStorage.getItem("user")).headImage;
    }
    // var that = this;
    this.game.info.game_type = this.$route.query.type; //获取游戏类型
    this.game.info.game_room = this.$route.query.room; //获取房间
    this.header.subTitle = this.game.info.game_room_type =
      this.$route.query.room_type; //获取房间类型
    this.header.mainTitle = this.game.info.game_name = this.$route.query.name; //获取游戏名字

    function startGameHome() {
      if (wsConnection && wsConnection.sock.readyState === 1) {
        that.$store.dispatch("LOGIN_tradingFloor", {
          room: that.$route.query.room,
          type: that.$route.query.type,
          callback: function () {
            that.system_message_function();
          },
        });
      } else {
        console.log("wsConnection not finish yet, attempt will be after 1sec.");
        setTimeout(function () {
          startGameHome();
        }, 1000);
      }
    }
    startGameHome();

    getTalkRoomSetting().then(function (result) {
      if (result.data.code == "SUCCESS") {
        if (result.data.result.length > 1) {
          result.data.result.forEach((item) => {
            that.game.info.badword =
              (that.game.info.badword === null ? "" : that.game.info.badword) +
              item.filtration +
              "/";
          });
        } else {
          that.game.info.badword = result.data.result[0].filtration;
        }
      }
    });

    this.$store.dispatch("GET_chatroomSettings", {});

    var key = "TEMPLATE" + _TEMPLATE.split("-")[1] + "_titleMenu";
    switch (this.$route.query.type) {
      case "bj28":
      case "jnd28":
      case "twbg28":
      case "mgelg28":
      case "jndx28":
      case "snfk28":
        if (
          this._TEMPLATE === "template-1" ||
          this._TEMPLATE === "template-2"
        ) {
          this.header.titleMenu = this[key].pc28;
        }
        break;
      case "cqssc":
      case "hljssc":
      case "tjssc":
      case "sscone":
      case "sscthree":
      case "sscfive":
      case "jndxy":
      case "azxy5":
        this.header.titleMenu = this[key].ssc;
        break;
      case "tiantiancai":
        this.header.titleMenu = this[key].ttc;
        break;
      case "bjsc":
      case "scone":
      case "scthree":
      case "scfive":
      case "xyft":
      case "azxy10":
        this.header.titleMenu = this[key].pk10;
        break;
      case "lhc":
      case "lhcaomen":
      case "lhcone":
      case "lhcthree":
      case "lhcfive":
      case "lhcten":
        this.header.titleMenu = this[key].lhc;
        break;
      case "tencent":
        this.header.titleMenu = this[key].tencent;
        break;
      case "gdfiveselect":
      case "sdfiveselect":
      case "jsfiveselect":
      case "jxfiveselect":
      case "shhfiveselect":
      case "anhfiveselect":
      case "shxfiveselect":
        this.header.titleMenu = this[key].fiveselect;
        break;
      case "fc3d":
        this.header.titleMenu = this[key].fc3d;
        break;
      case "gxklsf":
        this.header.titleMenu = this[key].klsf;
        break;
      case "gdklten":
        this.header.titleMenu = this[key].klten;
        break;
      case "cqklten":
        this.header.titleMenu = this[key].xync;
        break;
      case "wanneng":
        this.header.titleMenu = this[key].wanneng;
        break;
      case "damacai":
        this.header.titleMenu = this[key].damacai;
        break;
      case "duoduo":
        this.header.titleMenu = this[key].duoduo;
        break;
      case "yuedawan":
        this.header.titleMenu = this[key].yuedawan;
        break;
      case "shaba88":
        this.header.titleMenu = this[key].shaba88;
        break;
      case "saimahui":
        this.header.titleMenu = this[key].saimahui;
        break;
      case "xinjapowz":
        this.header.titleMenu = this[key].xinjapowz;
        break;
      default:
        break;
    }

    this.initTitleMenuClass();

    window.localStorage.setItem(
      "game",
      JSON.stringify({
        type: this.game.info.game_type,
        room: this.game.info.game_room,
      })
    );

    this.componentDocker.lastComponentIndex = this.horizonMenu.findIndex(
      (item) => {
        return item.highlight === true;
      }
    );
  },
  beforeUpdate() {
    // console.log('Component "'+this.$options.name+'" updating...')
  },
  beforeDestroy() {
    var that = this;
    console.log("ready to destroy GameHome component");
    clearInterval(this.timer.clean_setInterval);
    clearTimeout(this.timer.retriveLastDrawing);
    clearTimeout(this.timer.retriveCurrentDrawing);
    clearTimeout(this.timer.retriveCurrentDrawingRepeat);
    if (this.header.titleMenu) {
      this.header.titleMenu.forEach((item) => {
        item.active = false;
        item.childNav = false;
      });
    }

    function clearStore() {
      that.$store.state.currentRoomNumber = null;
      that.$store.state.currentGameType = null;
    }
    if (this.game.info.game_room) {
      roomLogout(this.game.info.game_room, this.game.info.game_type).then(
        (result) => {
          if (result.data.code == "SUCCESS") {
            console.log("ws room logout.");
            clearStore();
          }
        }
      );
    } else {
      clearStore();
    }
  },
  updated() {},
  provide() {
    return {
      auctioneerUIActive: this.auctioneerUIActive,
      calcHeight: this.calcHeight,
      highlightHorizonMenu: this.highlightHorizonMenu,
      game: this.game,
      user: this.user,
      addMessage: this.addMessage,
      orderAuction: this.send,
      updateBalance: this.function_account_balance,
      scrollEnd: this.scrollEnd,
      sendMessage: this.sendMessage,
      checkDxds: this.checkDxds,
      initTitleMenuClass: this.initTitleMenuClass,
      toggleHeaderMenuDocker: this.toggleHeaderMenuDocker,
      toggleChatRoom: this.toggleChatRoom,
      messageFilter: this.messageFilter,
    };
  },
};
</script>

<template>
  <section id="room" :class="_TEMPLATE">
    <template v-if="_TEMPLATE === 'template-1'">
      <Header
        :mainTitle="header.mainTitle"
        :subTitle="header.subTitle"
        :headerMenu="header.headerMenu"
        :titleMenu="header.titleMenu"
        :game="game"
        :gameType="game.info.game_type"
        :gameName="game.info.game_name"
        :toggleTitleMenuClass="toggleTitleMenuClass"
        :currentComponentIndex="componentDocker.lastComponentIndex"
        :auctioneerUI="auctioneerUI"
      ></Header>

      <Billboard
        :currentDrawing="this.game.currentDrawing"
        :lastDrawing="this.game.lastDrawing"
        :userBalance="this.user.balance"
        :countdownTimer="this.game.info.countdownTimer"
        :gameType="game.info.game_type"
        :nav="this.nav2"
      ></Billboard>

      <HorizonMenu
        v-show="!auctioneerUI"
        :horizonMenu="horizonMenu"
        :game="game"
      ></HorizonMenu>

      <section
        id="component-docker"
        :class="{
          animation: componentDocker.animating,
          reverse: componentDocker.reverse,
        }"
      >
        <transition
          name="slide"
          @before-enter="beforeEnter(horizonMenu)"
          @after-enter="afterEnter"
        >
          <template v-for="item in horizonMenu">
            <component v-if="item.highlight" :is="item.component"></component>
          </template>
        </transition>
      </section>
    </template>

    <template v-if="_TEMPLATE === 'template-2'">
      <Header
        :mainTitle="header.mainTitle"
        :subTitle="header.subTitle"
        :headerMenu="header.TEMPLATE2_headerMenu"
        :titleMenu="header.titleMenu"
        :game="game"
        :gameType="game.info.game_type"
        :gameName="game.info.game_name"
        :toggleTitleMenuClass="toggleTitleMenuClass"
        :currentComponentIndex="componentDocker.lastComponentIndex"
        :serviceIcon="true"
        :toggleComponentDocker="this.toggleComponentDocker"
        :backGameList="true"
        :userInfo="user"
      />

      <Billboard
        :currentDrawing="this.game.currentDrawing"
        :lastDrawing="this.game.lastDrawing"
        :userBalance="this.user.balance"
        :countdownTimer="this.game.info.countdownTimer"
        :gameType="game.info.game_type"
        :gameName="game.info.game_name"
        :toggleComponentDocker="this.toggleComponentDocker"
        :nav="this.nav2"
        :game="game"
      ></Billboard>

      <HorizonMenu
        v-if="!auctioneerUI && componentDockerToggle"
        :horizonMenu="horizonMenu2"
        :key="1"
      ></HorizonMenu>

      <section
        id="component-docker"
        :class="{
          animation: componentDocker.animating,
          reverse: componentDocker.reverse,
        }"
        v-if="!auctioneerUI && componentDockerToggle"
        :key="2"
      >
        <transition
          name="slide"
          @before-enter="beforeEnter(horizonMenu2)"
          @after-enter="afterEnter"
        >
          <template v-for="item in horizonMenu2">
            <component
              v-if="item.highlight"
              :is="item.component"
              :currentType="item.type"
            ></component>
          </template>
        </transition>
      </section>

      <TradingFloor
        v-if="tradingFloor && !componentDockerToggle && !ChatRoomToggle"
        :titleMenu="header.titleMenu"
        :headerMenuDockerOpen="this.headerMenuDockerOpen"
      />
      <keep-alive>
        <ChatRoom
          v-if="ChatRoomToggle && !componentDockerToggle"
          ref="chatroom"
          :ChatRoomToggle="this.ChatRoomToggle"
        />
      </keep-alive>
      <section
        id="header-menu-docker"
        ref="headerMenuDocker"
        v-if="headerMenuDockerOpen"
      >
        <transition
          name="slide-fade"
          @enter="slideFadeEnter"
          @after-enter="slideFadeAfterEnter"
          @leave="slideFadeLeave"
        >
          <template v-for="item in nav2">
            <component
              v-if="item.highlight"
              :is="item.component"
              :currentType="item.type"
            ></component>
          </template>
        </transition>
      </section>
    </template>

    <template v-if="_TEMPLATE === 'template-3'">
      <Header
        :mainTitle="header.mainTitle"
        :subTitle="header.subTitle"
        :headerMenu="header.TEMPLATE3_headerMenu"
        :titleMenu="header.titleMenu"
        :game="game"
        :gameType="game.info.game_type"
        :gameName="game.info.game_name"
        :toggleTitleMenuClass="toggleTitleMenuClass"
        :currentComponentIndex="componentDocker.lastComponentIndex"
        :auctioneerUI="auctioneerUI"
      ></Header>

      <Billboard
        :currentDrawing="this.game.currentDrawing"
        :lastDrawing="this.game.lastDrawing"
        :userBalance="this.user.balance"
        :countdownTimer="this.game.info.countdownTimer"
        :gameType="game.info.game_type"
      ></Billboard>

      <HorizonMenu
        v-show="!auctioneerUI"
        :horizonMenu="horizonMenu"
      ></HorizonMenu>

      <section
        id="component-docker"
        :class="{
          animation: componentDocker.animating,
          reverse: componentDocker.reverse,
        }"
      >
        <transition
          name="slide"
          @before-enter="beforeEnter(horizonMenu)"
          @after-enter="afterEnter"
        >
          <template v-for="item in horizonMenu">
            <component v-if="item.highlight" :is="item.component"></component>
          </template>
        </transition>
      </section>
      <keep-alive>
        <ChatRoom
          v-if="ChatRoomToggle"
          ref="chatroom"
          :game="game"
          :ChatRoomToggle="this.ChatRoomToggle"
        />
      </keep-alive>
    </template>
  </section>
</template>

<style lang="scss" scoped>
@import "@@/assets/style/_variables";
.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter {
  transform: translateX(0%);
}
.slide-enter-to {
  transform: translateX(-100%);
}

.slide-leave {
  transform: translateX(0%);
}
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-fade-enter-active {
  transition: all 0.7s ease;
}

.slide-fade-leave-active {
  transition: all 0.7s ease;
}

.slide-fade-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-fade-enter-active {
  transform: translateY(0%);
}

.slide-fade-leave-active {
  transform: translateY(100%);
}

.slide-fade-leave-active {
  opacity: 0;
}

#room {
  height: 100%;
  background-color: #f3f3f4;
  display: flex;
  flex-direction: column;
  color: #333333;
  font-size: 0.24rem;

  &.template-3 {
    #component-docker {
      overflow: auto;
    }
  }

  &.template-2 {
    color: #252525;

    #component-docker {
      > div {
        width: 100%;
        height: 100%;
      }
    }
  }

  #component-docker {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    flex: 1 1 0;

    &.animation {
      flex-direction: row;
      width: 200%;

      > * {
        width: 50%;
      }
    }

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  #header-menu-docker {
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: 3;
    top: 3.25rem;

    > .query_record {
      background-color: #ffffff;
    }

    &.active {
      > div {
        position: absolute;
        width: 100%;
      }
    }
  }
}
</style>
